<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- QUESTION -->
                            <b-col md="12">
                                <b-form-group
                                    label="PREGUNTA"
                                    label-for="question"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="pregunta"
                                    >
                                        <b-form-textarea
                                            id="question"
                                            v-model="formData.question"
                                            class="char-textarea"
                                            placeholder="Ingrese la pregunta e instrucciones ..."
                                            :state="errors.length > 0 ? false : null"
                                            rows="3"
                                        />
                                        <small class="textarea-counter-value float-right">
                                            <strong class="char-count">Caracteres: {{ formData.question ? formData.question.length : 0 }}</strong>
                                        </small>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>		
                                </b-form-group>
                            </b-col>

                            <!-- QUESTION TYPE -->
                            <b-col md="12">
                                <b-form-group
                                    label="TIPO DE PREGUNTA"
                                    label-for="questionType"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de pregunta"
                                    >
                                        <v-select
                                            id="questionType"
                                            v-model="formData.questionType"
                                            :state="errors.length > 0 ? false : null"
                                            :options="options.questionTypes"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- ALTERNATIVES -->
                            <b-col md="12" v-if="formData.questionType">
                                <hr>
                                <b-form-group
                                    label="ALTERNATIVAS"
                                    class="question-options"
                                >  

                                    <template v-if="formData.questionType.value === 1">
                                        <b-form-radio
                                            class="b-form-radio"
                                            v-for="(alternative, index) in alternatives"
                                            :key="index"
                                            v-model="marked"
                                            :value="alternative"
                                        >
                                            <b-form-input v-model="alternative.text" trim></b-form-input>
                                            <b-button
                                                v-if="index !== 0"
                                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                variant="outline-danger"
                                                class="ml-1"
                                                @click="removeAlternative(index, 'radio', alternative.id)"
                                            >
                                                <feather-icon icon="Trash2Icon" />
                                            </b-button>
                                        </b-form-radio>
                                    </template>

                                    <template v-if="formData.questionType.value === 2">
                                        <b-form-checkbox
                                            class="b-form-radio"
                                            v-for="(alternative, index) in alternatives"
                                            :key="index"
                                            v-model="marked"
                                            :value="alternative"
                                        >
                                            <b-form-input v-model="alternative.text" trim></b-form-input>
                                            <b-button
                                                v-if="index !== 0"
                                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                                variant="outline-danger"
                                                class="ml-1"
                                                @click="removeAlternative(index, 'checkbox', alternative.id)"
                                            >
                                                <feather-icon icon="Trash2Icon" />
                                            </b-button>
                                        </b-form-checkbox>
                                    </template>

                                </b-form-group>
                                
                                <hr>

                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    size="sm"
                                    @click="addAlternative"
                                >
                                    <feather-icon
                                        icon="PlusIcon"
                                        class="mr-25"
                                    />
                                    <span>AGREGAR ALTERNATIVA</span>
                                </b-button>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="AGREGAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, watch, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import questionStoreModule from '../questionStoreModule';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import { v4 as uuidv4 } from 'uuid';
    import vSelect from 'vue-select';
    import router from '@/router';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,
            vSelect
        },
        directives: {
            Ripple
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const alternatives = ref([ { id: uuidv4(), text: null } ]);
            const refForm = ref(null);
            const marked = ref({});

            const formData = ref({
                question: null,
                questionType: null
            });

            const processing = ref(false);
            const options = ref({});

            const QUESTION_APP_STORE_MODULE_NAME = 'app-question';

			// REGISTER MODULE
			if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, questionStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/question-types');
                
				options.value = data;
            };

            watch(() => formData.value.questionType, (newValue) => {
                if (newValue) marked.value = (newValue.value === 1 ? {} : []);
                else resetAlternatives();
            });

            const resetFormData = () => {
                formData.value = {
                    question: null,
                    questionType: null
                }
            };

            const resetAlternatives = () => {
                marked.value = {};
                alternatives.value = [ { id: uuidv4(), text: null } ];
            };

            const addAlternative = () => {
                
                alternatives.value.push({
                    id: uuidv4(),
                    text: null
                });

            };

            const removeAlternative = (index, type, id) => {

                alternatives.value.splice(index, 1);

                if (type === 'radio'){
                    if (marked.value.id === id) marked.value = {};
                } else {
                    marked.value = marked.value.filter(u => u.id !== id);
                }

            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                let error = 0;

                alternatives.value.map(item => {
                    if (!item.text) error = 1;
                });

                if (error)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete la información de las alternativas.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                if (formData.value.questionType.value === 1 && !marked.value.id)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe seleccionar una opción correcta.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                        
                    return;
                }

                if (formData.value.questionType.value === 2 && marked.value.length < 2)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe seleccionar 2 opciones correctas, como mínimo.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                        
                    return;
                }

                processing.value = true;

                const correctAnswers = formData.value.questionType.value === 2 ? marked.value.map(item => item.id) : [ marked.value.id ];

                const answers = alternatives.value.map(alternative => 
                {
                    const is_correct = (correctAnswers.includes(alternative.id)) ? 1 : 0;

                    return ({ answer: alternative.text, is_correct });
                });

                const payload = {
                    questionText: formData.value.question,
                    questionTypeId: formData.value.questionType.value,
                    answers
                }

                store.dispatch('app-question/addQuestion', { payload })
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        resetFormData();
                        resetAlternatives();

                        refForm.value.reset();

                        setTimeout(() => {
                            router.push({ name: 'questions-edit', params: { id: response.data.id } });
                        }, 2000);

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : 'Error al crear la pregunta.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                        processing.value = false
                    });

            };

            return {
                // DATA
                processing,
                formData,
                options,

                // REFS
                alternatives,
                refForm,
                marked,

                // METHODS
                addAlternative,
                removeAlternative,
                onSubmit
            }
        }
    }

</script>

<style>

    .question-options .custom-radio .custom-control-label,
    .question-options .custom-checkbox .custom-control-label {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .question-options .custom-radio .custom-control-label::before,
    .question-options .custom-checkbox .custom-control-label::before {
        top: 0.72rem !important;
    }

    .question-options .custom-radio .custom-control-label::after,
    .question-options .custom-checkbox .custom-control-label::after {
        top: 0.72rem !important;
    }

    .question-options .custom-control.custom-radio,
    .question-options .custom-control.custom-checkbox {
        margin-bottom: 1rem;
    }

    .question-options .custom-control.custom-radio:nth-last-child(1),
    .question-options .custom-control.custom-checkbox:nth-last-child(1) {
        margin-bottom: 0;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>